export default [
  {
    title: 'Dashboards',
    icon: 'HomeIcon',
    route: 'dashboard-analytics',
    permissions: ['dashboard', 'faq', 'page', 'blogs'],
  },
  {
    header: 'Real Estate Settings',
    permissions: ['real-state', 'real-state-type', 'related-service', 'real estate', 'real-estate-view', 'real-estate-sell-request'],
  },
  {
    title: 'Real Estate Types',
    icon: 'HomeIcon',
    route: 'real-state-type',
    permissions: ['real-state-type'],
  },
  {
    title: 'real-estates',
    icon: 'HomeIcon',
    route: 'real-estate',
    permissions: ['real estate'],
  },
  {
    title: 'Related Service',
    icon: 'ListIcon',
    route: 'related-service',
    permissions: ['related-service'],
  },
  {
    title: 'Real Estate View',
    icon: 'ListIcon',
    route: 'real-estate-view',
    permissions: ['real-estate-view'],
  },
  {
    title: 'real-estate-requests',
    icon: 'ListIcon',
    route: 'sale-requests',
    permissions: ['real-estate-sell-request'],
  },
  {
    header: 'Roles & Users',
    permissions: ['users', 'roles'],
  },
  {
    title: 'Users',
    icon: 'UserIcon',
    route: 'users',
    permissions: ['users'],
  },
  {
    title: 'Roles & Permissions',
    icon: 'UsersIcon',
    route: 'roles',
    permissions: ['roles'],
  },
  {
    title: 'Customers',
    icon: 'UserIcon',
    route: 'customers',
    permissions: ['customer'],
  },
  {
    header: 'Facilities',
    permissions: ['facility-district', 'facility-type'],
  },
  {
    title: 'Facilities Districts',
    icon: 'ListIcon',
    route: 'facilities-districts',
    permissions: ['facility-district'],
  },
  {
    title: 'Facility Types',
    icon: 'ListIcon',
    route: 'facilities-types',
    permissions: ['facility-type'],
  },
  {
    header: 'country_and_region_settings',
    permissions: ['countries', 'regions', 'cities', 'districts'],
  },
  {
    title: 'Countries',
    icon: 'MapIcon',
    route: 'countries',
    permissions: ['countries'],
  },
  {
    title: 'Regions',
    icon: 'MapIcon',
    route: 'regions',
    permissions: ['regions'],
  },
  {
    title: 'Cities',
    icon: 'MapIcon',
    route: 'cities',
    permissions: ['cities'],
  },
  {
    title: 'Districts',
    icon: 'MapIcon',
    route: 'districts',
    permissions: ['districts'],
  },

  {
    header: 'general_settings',
    permissions: ['settings'],
  },
  {
    title: 'Setting',
    icon: 'SettingsIcon',
    route: 'settings',
    permissions: ['settings', 'faq', 'page-builder'],
  },
  {
    title: 'FAQs',
    icon: 'HelpCircleIcon',
    route: 'faqs',
    permissions: ['faq'],
  },
  {
    title: 'Pages',
    icon: 'BookOpenIcon',
    route: 'pages-builder',
    permissions: ['page-builder'],
  },
  {
    header: 'technical support',
    permissions: ['contactus', 'conflict'],
  },
  {
    title: 'Contact Us',
    icon: 'PhoneIcon',
    route: 'contact-us',
    permissions: ['contactus'],
  },
  {
    title: 'Conflicts',
    icon: 'FlagIcon',
    route: 'conflicts',
    permissions: ['conflict'],
  },
  {
    header: 'Blogs and news',
    permissions: ['blogs', 'blog categories', 'newsletter'],
  },
  {
    title: 'Blogs',
    icon: 'CircleIcon',
    route: 'blogs',
    permissions: ['blogs'],
  },
  {
    title: 'Blog Categories',
    icon: 'CircleIcon',
    route: 'blog-categories',
    permissions: ['blog categories'],
  },
  {
    title: 'News Letter',
    icon: 'MailIcon',
    route: 'newsletter',
    permissions: ['newsletter'],
  },
  // {
  //   header: 'Ads Management',
  //   permissions: ['advertisement', 'advertisementcategory'],
  // },
  // {
  //   title: 'Advirtisements',
  //   icon: 'Volume2Icon',
  //   route: 'advertisements',
  //   permissions: ['advertisement'],
  // },
  // {
  //   title: 'Ads Categories',
  //   icon: 'Volume1Icon',
  //   route: 'advertisements/categories',
  //   permissions: ['advertisementcategory'],
  // },
]
